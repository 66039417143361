// console.log('main.ts');
// import { get, readable, writable } from 'svelte/store';
// import { cfg, client, page } from './stores';
import { ae } from 'aether_npm_lib';
// import testing from './testing/testing_main.svelte';
import AI_main from './0_ai_main.svelte';
// BEGIN: Aether core
// BEGIN: Aether modules
// console.log(document.aether.cfg);
// console.log(document.aether.client);
// console.log(document.aether.page);
let url_params = ae.util.get_url_params();
// console.log(url_params);
if (document.querySelector('.svelte_target.ae_ai_main')) {
    const app_main = new AI_main({
        target: document.querySelector('.svelte_target.ae_ai_main'),
        props: {
            'example': url_params.example,
        }
    });
}
