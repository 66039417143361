<script lang="ts">
type key_val = {
    [key: string]: any;
};
// *** Import Svelte core
import { onMount } from 'svelte';

// *** Import Aether core variables and functions
import { ae, api, Element_modal_v3 } from 'aether_npm_lib';

import { client, page, slct, slct_trigger, ae_com, ae_ai } from './stores';


onMount(() => {
    console.log('** Component Mounted: ** AE AI Main');
});
</script>

<section class="ae_ai_main">
    Hello World!
</section>